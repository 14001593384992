import React, { useEffect } from "react"
import { navigate } from "gatsby-link"
import { getDefaultLoginRoute } from "~/screens/login/useDefaultLoginRoutet"
import ExternalNothingToSee from "~/pages/external/404"

function IndexPage() {
  useEffect(() => {
    if (isBlank) return
    navigate(getDefaultLoginRoute())
  }, [])
  if (isBlank) return <ExternalNothingToSee />
  return null
}

const isBlank = !!process.env.GATSBY_IS_BLANK_APP

export default IndexPage
